.contact-container {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-left: 100px;
}

.contact-left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.contact-headline {
    color: white;
    font-size: 48px;
}

.contact-text {
    color: white;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.contact-form {
    display: flex;
    flex-direction: column;
    padding: 20px 50px 10px 50px;
    background: rgba(118, 171, 174, 0.09);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(17.9px);
    -webkit-backdrop-filter: blur(17.9px);
    border: 1px solid rgba(118, 171, 174, 0.21);
}

.form-headline {
    font-size: 42px;
    color: #EEEEEE;
    margin-bottom: 20px;
}

input {
    width: 100%;
    height: 40px;
    margin-bottom: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    text-indent: 10px;
    background-color: #222831;
    border: none;
    color: #EEEEEE;
    
}

.form-text {
    height: 100px;
}

::placeholder {
    color: #EEEEEE;
}
.contact-image {
    height: 350px;
    width: auto;
}

.contact-left-text{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.contact-flex {
    display: flex;
    flex-direction: column;
}

.contact-left {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
}

.contact-text {
    color: #EEEEEE;
    font-size: 22px;
}

.contact-email {
    font-size: 22px;
    color: #76ABAE;
}

.contact-button {
    -webkit-border-radius: 50px;
   -moz-border-radius: 50px;
   border-radius: 50px;
   height: 100px;
   line-height: 10px;
   color: #222831;
   width: 100px;
   font-size: 50px;
   font-weight: 300;
   background-color: #76ABAE;
   -webkit-box-shadow: 1px 1px 20px 0 #000000;
   -moz-box-shadow: 1px 1px 20px 0 #000000;
   box-shadow: 1px 1px 20px 0 #000000;
   text-shadow: 1px 1px 20px #000000;
   border: solid #000000 1px;
   text-decoration: none;
   display: inline-block;
   cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
}

.footer {
    position: absolute;
    top: 95%;
    left: 50%;
    transform: translatex(-50%);
    color: #EEEEEE;
}

@media only screen and (min-width: 768px) and (max-width: 1092px){
    .contact-left {
        display: flex;
        flex-direction: row;
        gap: 0px;
    }
    .contact-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-left: 0px;
        gap: 50px;
    }
    .contact-form{
        height: auto;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px){
    .contact-left {
        display: flex;
        flex-direction: row;
        gap: 0px;
    }
    .contact-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-left: 0px;
        gap: 50px;
    }
    .contact-form{
        height: auto;
    }
    .contact-image{
        height: 200px;
    }
}

@media only screen and (min-width: 1px) and (max-width: 576px){
    .contact-left {
        display: flex;
        flex-direction: row;
        gap: 0px;
        margin-top: 10px;
        display: none;
    }
    .contact-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-left: 0px;
        gap: 20px;
        height: 100svh;
    }
    .contact-form{
        height: auto;
    }
    .contact-image{
        height: 100px;
        margin-right: 25px;
    }
    .form-headline {
        font-size: 22px;
    }
    .contact-button {
        width: 50px;
        height: 50px;
        font-size: 24px;
        font-weight: 500;
    }
    .contact-text{
        font-size: 14px;
    }
    .contact-email {
        font-size: 14px;
    }
    .footer {
        white-space: nowrap;
    }
    input {
        height: 30px;
    }

}