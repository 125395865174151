
.slide-in {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: #222831;
    transform-origin: bottom;
    z-index: 2;
}

.slide-out {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: #222831;
    transform-origin: top;
    z-index: 2;
}

* {
    margin: 0px;
    padding: 0px;
    font-family: "Open Sans", sans-serif;
}