nav {
    position: absolute;
    width: 100px;
    height: 100vh;
    background-color: #222831;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}

.bottom-nav {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 30px;
    margin-bottom: 5vh;
}

@media only screen and (min-width: 1px) and (max-width: 1092px){
    nav {
        display: none;
    }

    .outer-menu .hamburger {
        background-color: transparent;
    }
}